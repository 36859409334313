import React from 'react'

function Nav() {
    
  return (
    <nav className='nav hidden lg:block'>
        <ul className='mt-16 w-max'>
            <li>
                <a className='group flex items-center py-3' href="#about">
                    <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
                    <span className="nav-text text-xs font-bold uppercase tracking-widest opacity-75 group-hover:text-off-white group-hover:opacity-100 group-focus-visible:text-off-white group-focus:opacity-100">About</span>
                </a>
            </li>
            <li>
                <a className='group flex items-center py-3' href="#experience">
                    <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
                    <span className="nav-text text-xs font-bold uppercase tracking-widest opacity-75 group-hover:text-off-white group-hover:opacity-100 group-focus-visible:text-off-white group-focus:opacity-100">
                        Experience
                    </span>
                </a>
            </li>
            <li>
                <a className='group flex items-center py-3' href="#projects">
                    <span className="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
                    <span className="nav-text text-xs font-bold uppercase tracking-widest opacity-75 group-hover:text-off-white group-hover:opacity-100 group-focus-visible:text-off-white group-focus:opacity-100">
                        Projects
                    </span>
                </a>
            </li>
                
        </ul>
    </nav>
  )
}

export default Nav