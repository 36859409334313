import React from 'react'

function About() {
  return (
    <section id="about" className='mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24'>
        <p>
            Back in 2017, I attended my first hackathon and wrote my first line of python for a text adventure game.
            It was love at first type. Since then, I've been learning and building projects in my free time, and have
            had the privilege of working with some amazing people.

            <br /><br />

            I'm currently a Frontend Developer at the <a className="text-off-white hover:text-cherry-blossom focus-visible:text-cherry-blossom  group/link text-base" href="https://www.ufl.edu/" target="_blank" rel="noreferrer" aria-label="University of Florida"><span className='font-semibold'>University of Florida </span></a>  
            working with cross-functional teams and clients to build and maintain the university's apps and web presence.
            For professional development, I have been teaching developers in my team React.JS and related frameworks and libraries.

            <br /><br />

            When I have free time, I love exploring places and being a foodie. I also enjoy playing video games, digital art, and photography.
        </p>
    </section>
  )
}

export default About