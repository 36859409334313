import React from 'react';

const TechTag = ({ tech }) => (
  <li className="my-1 mr-1.5">
    <div className="flex items-center rounded-full bg-cherry-blossom bg-opacity-15 px-3 py-1 text-xs font-medium leading-5 text-cherry-blossom">
      {tech}
    </div>
  </li>
);

export default TechTag;