import UFPL from './assets/projectimgs/UFPL.png';
import IT from './assets/projectimgs/it.ufl.edu.png';
import Commencement from './assets/projectimgs/commencement.png';

export const archive = [
    {
      year: '2024',
      projectName: 'TeamDynamix System Integration',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'PHP', 'Postman', 'TeamDynamix API'],
      link: 'https://www.teamdynamix.com',
      linkTitle: 'TeamDynamix'
    },
    {
      year: '2024',
      projectName: 'IT Redesign at UF',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'TerminalFour', 'HTML', 'CSS', 'JavaScript', 'SlickJS', 'Bootstrap 5'],
      link: 'https://it.ufl.edu',
      linkTitle: 'University of Florida Information Technology'
    },
    {
      year: '2023',
      projectName: 'Mercury Pattern Library',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'TerminalFour', 'HTML', 'CSS', 'JavaScript', 'SlickJS', 'Bootstrap 5'],
      link: 'https://webservices.it.ufl.edu/mercury-theme/',
      linkTitle: 'UF Mercury Pattern Library'
    },
    {
      year: '2023',
      projectName: 'PolicyHub',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'PHP', 'HTML', 'CSS', 'JavaScript'],
      link: 'https://policy.ufl.edu',
      linkTitle:'https://policy.ufl.edu'
    },
    {
      year: '2023',
      projectName: 'LISTSERV Page Template',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'PHP', 'HTML', 'CSS', 'JavaScript'],
      link: 'https://github.com/jlayog/listserv-form-page-template',
      linkTitle: 'Github'
    },
    {
      year: '2023',
      projectName: 'Hamilton Center',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://hamilton.center.ufl.edu',
      linkTitle: 'hamilton.center.ufl.edu'
    },
    {
      year: '2022',
      projectName: 'President Search',
      madeAt: 'University of Florida',
      builtWith: ['WordPress', 'PHP', 'HTML', 'CSS', 'JavaScript'],
      link: 'https://president.ufl.edu',
      linkTitle: 'president.ufl.edu',
    },
    {
      year: '2022',
      projectName: 'Board of Trustees',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://trustees.ufl.edu',
      linkTitle: 'trustees.ufl.edu',
    },
    {
      year: '2022',
      projectName: 'Academic Technology Site Migration',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://at.ufl.edu',
      linkTitle: 'at.ufl.edu',
    },
    {
      year: '2021',
      projectName: 'Technology Support Services',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://tss.it.ufl.edu',
      linkTitle: 'tss.it.ufl.edu',
    },
    {
      year: '2021',
      projectName: 'Enterprise Systems Site Migration',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: '',
      linkTitle: '',
    },
    {
      year: '2021',
      projectName: 'GrammarCruncher',
      madeAt: 'University of Florida',
      builtWith: ['React', 'SCSS', 'JSX', 'PHP'],
      link: 'https://languages.ufl.edu/people/faculty-alpha/will-hasty/german-grammar-cruncher/',
      linkTitle: 'GrammarCruncher',
    },
    {
      year: '2021',
      projectName: 'Faculty Senate',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://senate.ufl.edu',
      linkTitle: 'senate.ufl.edu',
    },
    {
      year: '2020',
      projectName: 'Research Computing',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://rc.ufl.edu',
      linkTitle: 'rc.ufl.edu',
    },
    {
      year: '2020',
      projectName: 'UF Directory',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'PHP', 'JavaScript'],
      link: 'https://directory.ufl.edu',
      linkTitle: 'https://directory.ufl.edu',
    },
    {
      year: '2020',
      projectName: 'Commencement at UF',
      madeAt: 'University of Florida',
      builtWith: ['HTML', 'CSS', 'JavaScript', 'TerminalFour (CMS)'],
      link: 'https://commencement.ufl.edu',
      linkTitle: 'commencement.ufl.edu',
    },
];

// datalist.js
export const experienceCardsData = [
    {
      dateRange: 'Nov 2023 — Present',
      position: 'Frontend Developer II',
      organization: 'University of Florida',
      organizationLink: 'https://ufl.edu',
      description:
        'Lead in the development and refinement of accessible, compliant web solutions, adhering to established brand guidelines and technology frameworks. Oversee projects from concept through to deployment, focusing on functionality and alignment with organizational goals. Provide mentorship to junior developers, emphasizing collaborative development and adherence to best practices.',
      tags: ['React', 'WordPress', 'JavaScript', 'PHP', 'CSS', 'HTML5', 'TerminalFour (CMS)'],
    },
    {
      dateRange: 'May 2020 — Nov 2023',
      position: 'Frontend Developer I',
      organization: 'University of Florida',
      organizationLink: 'https://ufl.edu',
      description:
        'Developed user-friendly interfaces, ensured web accessibility, and utilized the latest technologies to provide an engaging and intuitive online experience. Working in concert with cross-functional teams, designing concepts into functional, efficient websites and applications that supported our institution\'s educational goals.',
      tags: ['React', 'WordPress', 'JavaScript', 'PHP', 'CSS', 'HTML5', 'TerminalFour (CMS)'],
    },
    { 
        dateRange: "Sep 2019 — May 2020",
        position: "Web Designer",
        organization:"University of Florida",
        organizationLink: "https://ufl.edu",
        description: "Designed ADA-compliant websites and applications, collaborated with cross-functional teams, and kept abreast of the latest design trends to enhance our institution's digital presence and support our mission of delivering a top 5 university education.",
        tags: ["Figma", "Photoshop", "CSS", "HTML", "JavaScript", "PHP", "TerminalFour (CMS)"]
    },
    {
        dateRange: "Feb — Sep 2019",
        position: "Web Technician",
        organization: "University of Florida",
        organizationLink: "https://ufl.edu",
        description: "Maintained and enhanced our organization's online presence. My duties involve troubleshooting technical issues, updating website content, assisting in the development and implementation of site improvements, and ensuring optimal website functionality.",
        tags: ["CSS", "HTML", "JavaScript", "TerminalFour (CMS)"]
    },
    // More experience cards...
];
  

export const projects = [
    {
      link: 'https://webservices.it.ufl.edu/mercury-theme/',
      title: 'Mercury Pattern Library',
      description: 'Engineered pattern library components to modernize the brand theme for a public university, enhancing component reusability and consistency across digital platforms. Complete with well-documented components and guidelines on usage scenarios.',
      imgSrc: UFPL,
      tags: ['WordPress', 'TerminalFour', 'HTML', 'CSS', 'JavaScript', 'SlickJS', 'Bootstrap 5'],
    },
    {
      link: 'https://it.ufl.edu',
      title: 'IT Redesign at UF',
      description: "Collaborated with a team of developers and designers to redesign the University of Florida's IT website, a hub for IT services, resources, and news. The project involved a complete overhaul of the site's design and functionality.",
      imgSrc: IT,
      tags: ["HTML", "CSS", "jQuery", "JavaScript", "TerminalFour (CMS)"],
    },
    {
      link: 'https://commencement.ufl.edu',
      title: 'Commencement at UF',
      description: "Led the development of the University of Florida's Commencement Website, a go-to resource for students and families seeking information on graduation ceremonies, times, and other related matters.",
      imgSrc: Commencement,
      tags: ["HTML", "CSS", "jQuery", "JavaScript", "TerminalFour (CMS)"]
    },
  ];

  export const messages = [
    "Hi, I am Jerel Layog. I am a Frontend Web Developer.",
    "I love creating software and web apps.",
    "Looking to hire?",
    "Check out my work."
  ];