import React, { useState } from "react";
import Message from "./Message";
import { messages } from "../../data";

const DialogBox = () => {
    const [currentMessage, setCurrentMessage] = useState(0);
    const handleClick = () => {
        if (currentMessage < messages.length - 1) {
            setCurrentMessage(currentMessage + 1);
        } else {
            setCurrentMessage(0);
        }
    };
  
  return (
    <div
      className="bg-[#1d2459] text-2xl relative p-6 border mt-6 sm:m-0 sm:max-lg:mt-6 cursor-pointer z-10 sm:min-w-[400px] max-w-[60%] w-[500px] h-[150px] dialogWindow min-w-[340px]"
      onClick={handleClick}
    >
      <Message message={messages[currentMessage]} key={currentMessage} />
      <button className="text-lg absolute bottom-0 right-0 mb-2 mr-4">▼</button>
    </div>
    );
};
  
export default DialogBox;