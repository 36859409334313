import React from 'react'

const Footer = () => {
  return (
    <footer className="max-w-md pb-16 text-sm text-slate-500 sm:pb-0">
        <p>Design inspired by Brittany Chiang. Built with React.js and Tailwind CSS, deployed with Vercel.</p>
    </footer>
  )
}

export default Footer;