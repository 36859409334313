import React from 'react'
import About from './About'
import Experience from './Experience/Experience.js'
import Projects from './Projects/Projects.js'
import Footer from './Footer'


function Content() {
  return (
    <main className='pt-24 lg:w-1/2 lg:py-24'>
        <About />
        <Experience />
        <Projects />
        <Footer />
    </main>
  )
}

export default Content