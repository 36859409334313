import React from 'react';
import TableRow from '../components/Archive/TableRow';
import { archive } from '../data';
import useDocumentTitle from '../hooks/useDocumentTitle';

const Archive = (props) => {
  useDocumentTitle(props.title);
  return (
    <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
      <a href="#content" class="absolute left-0 top-0 block -translate-x-full rounded bg-gradient-to-br from-cherry-blossom via-blue-500 to-purple-600 px-4 py-3 text-sm font-bold uppercase tracking-widest text-white focus-visible:translate-x-0">Skip to Content</a>
      <div className="lg:py-24">
        <a className="group mb-2 inline-flex items-center font-semibold leading-tight text-cherry-blossom" href="/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="mr-1 h-4 w-4 rotate-180 transition-transform group-hover:-translate-x-2" aria-hidden="true"><path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd"></path></svg>Jerel Layog</a>
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">All Projects</h1>
        <table id="content" className="mt-12 w-full border-collapse text-left">
          <thead className="sticky top-0 z-10 border-b border-slate-300/10 bg-dark-blue px-6 py-5 backdrop-blur">
            <tr>
              <th className="py-4 pr-8 text-sm font-semibold text-slate-200">Year</th>
              <th className="py-4 pr-8 text-sm font-semibold text-slate-200">Project</th>
              <th className="hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell">Made at</th>
              <th className="hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell">Built with</th>
              <th className="hidden py-4 pr-8 text-sm font-semibold text-slate-200 sm:table-cell">Link</th>
            </tr>
          </thead>
          <tbody>
            {archive.map((item, index) => (
              <TableRow key={index} {...item} />
            ))}
          </tbody>
        </table> 
      </div>   
    </div>
  );
};

export default Archive;