import Home from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import NoPage from './pages/NoPage';
import Archive from './pages/Archive';
import { inject } from '@vercel/analytics';

function App() {
  inject();
  return (
    <Routes>
      <Route index element={<Home title="Jerel Layog" />} />
      <Route path="*" element={<NoPage title="404!" />} />
      <Route path="/archive" element={<Archive title="Archive | Jerel Layog" />} />
    </Routes>
  );
}

export default App;
