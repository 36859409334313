import { useMemo } from 'react';
import { useTransition, animated } from '@react-spring/web';

const Message = ({ message }) => {
    const items = useMemo(
      () =>
        message.split("").map((letter, index) => ({
          item: letter,
          key: index,
        })),
      [message]
    );
  
    const transitions = useTransition(items, {
      keys: (item) => item.key,
      trail: 45,
      from: { display: "none" },
      enter: { display: "" },
    });
  
    return (
      <div className="dialogMessageCaret text-center sm:text-left">
        {transitions((props, item) => {
          return (
            <animated.span style={props}>
              {item.item}
            </animated.span>
          );
        })}
      </div>
    );
};
  
export default Message;
  